.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.design {
    position: fixed;
    float: left;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.polyleft {
    fill: #843b62;
    /* fill: #37393a; */
    /* fill: #015d53; */
    /* fill: #60938088; */
    /* fill: linear-gradient(to bottom, #015d53, transparent); */
}

.polyright {
    fill: #bbc2cc;
    opacity: 50%;
}

@media screen and (max-width: 479px) {
    .layout {
        padding-bottom: 100px;
    }
}