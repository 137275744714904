.navbar {
    margin: 16px 0px;
    padding: 8px;
    background-color: #37393a;
    color: #a2a2a1;
    font-size: 24px;
    border-radius: 24px;
    transition: .3s;
    z-index: 5;
}

.navbar-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: 0.3s;
}

.navbar-li {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 6px 0px;
    transition: .3s;
}

.navlink {
    text-decoration: none;
    text-align: center;
    padding: 6px 42px;
    color: #f1f4ff;
    transition: .3s;
    border-bottom: solid 2px transparent;
}

.active {
    border-bottom: solid 2px #FF3633;
    border-radius: 8px;
    transition: .3s;
}

@media screen and (max-width: 800px) {
    .navlink {
        padding: 8px 8px;
    }
}

@media screen and (max-width: 479px) {
    .navbar {
        position: fixed;
        bottom: 0;
        font-size: 18px;
        margin: 0px 0px;
    }

    .navlink {
        padding: 8px 8px;
    }
}